@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.colored-separator {
  background: linear-gradient(
    90deg,
    rgba(0, 133, 255, 0.5) -1.31%,
    rgba(255, 12, 201, 0.5) 99.98%
  );
}

#root {
  max-width: 1920px;
  margin: 0 auto;
}

body {
  -webkit-font-smoothing: antialiased;
}

::-moz-selection {
  /* Code for Firefox */
  color: #482bd9;
  background: #41d6cd;
}

::selection {
  color: #482bd9;
  background: #41d6cd;
}
